import { template as template_58501b6377b24fd3a96e5dbc8b47d2c6 } from "@ember/template-compiler";
const FKLabel = template_58501b6377b24fd3a96e5dbc8b47d2c6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
