import { template as template_40799da58aea447d814d811f8ac18c0c } from "@ember/template-compiler";
const WelcomeHeader = template_40799da58aea447d814d811f8ac18c0c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
